<template>
  <div style="width: 100%; position: relative">
    <div class="common-gaine flex-cen-cen" style="width: 600px">
      <div style="width: 400px">
        <div class="common-plate-title">
          <span>{{
            $route.query.type == 'add'
              ? $trans('新建地址', '新建地址')
              : $trans('修改地址', '修改地址')
          }}</span>
          <i
            class="iconfont icon-fanhui1"
            :title="$trans('返回上一页', '返回上一页')"
            @click="$router.history.go(-1)"
          ></i>
        </div>
        <div class="address-box-title font-medium">
          {{ $trans('联系方式', '联系方式') }}
        </div>
        <el-input
          v-model="form.contactName"
          :placeholder="$trans('请输入联系人姓名', '请输入联系人姓名')"
        ></el-input>
        <el-input
          v-model="form.contactPhone"
          :placeholder="$trans('请输入联系人电话', '请输入联系人电话')"
        ></el-input>
        <div class="address-box-title font-medium">
          {{ $trans('送货地址', '送货地址') }}
        </div>
        <el-cascader
          :placeholder="$trans('请选择收货地址', '请选择收货地址')"
          :props="{ value: 'adcode', label: 'name', children: 'districts' }"
          :options="options"
          v-model="form.areaId"
          filterable
        ></el-cascader>
        <el-input
          v-model="form.addrDetail"
          :placeholder="$trans('请输入详细地址', '请输入详细地址')"
        ></el-input>
        <div class="btn-fill" @click="submitHandle">
          {{ $trans('保存', '保存') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getGeography, addAddress, editAddress } from '@/util/js/api.js'
export default {
  name: '',
  data() {
    return {
      options: [], // 地址选项
      form: {
        contactName: '',
        contactPhone: '',
        addrDetail: '',
        areaId: [], // 省市县值
      },
    }
  },
  mounted() {
    this.initForm()
  },
  methods: {
    // 初始化数据
    initForm() {
      this.getGeography()
      if (this.$route.query.type != 'add') {
        this.form = this.$route.query.address
          ? { ...JSON.parse(this.$route.query.address) }
          : { ...this.form }
      }
    },
    // 获取省市县地址
    getGeography() {
      getGeography().then((res) => {
        let arr = JSON.parse(JSON.stringify(res))
        arr = arr.map((item) => {
          return this.deepTraverse(item)
        })
        this.options = arr
        this.initAreaId()
      })
    },
    // 深度遍历删除空节点
    deepTraverse(item) {
      if (item.districts && item.districts.length < 1) {
        delete item.districts
        return item
      } else {
        item.districts = item.districts.map((ite) => {
          ite = this.deepTraverse(ite)
          return ite
        })
        return item
      }
    },
    // 提交
    submitHandle() {
      let contactPhoneReg = /^1[3578]\d{9}$/
      if (
        this.form.contactName &&
        contactPhoneReg.test(this.form.contactPhone) &&
        this.form.addrDetail &&
        this.form.areaId
      ) {
        if (this.form.areaId.length == 0) {
          this.$message.warning(this.$trans('请填写收货地址', '请填写收货地址'))
          return
        }
        let addrFull = ''
        let obj = JSON.parse(JSON.stringify(this.options))
        let arr = JSON.parse(JSON.stringify(this.form.areaId))
        let newArr = [this.changeAreaText(arr[0], obj)]
        arr.slice(1).forEach((item, index) => {
          newArr.push(this.changeAreaText(item, newArr[index].districts))
        })
        addrFull +=
          newArr.reduce((a, b) => a + b.name, '') + this.form.addrDetail
        let param = {
          addrDetail: this.form.addrDetail,
          addrFull: addrFull,
          areaId: this.form.areaId[this.form.areaId.length - 1],
          contactName: this.form.contactName,
          contactPhone: this.form.contactPhone,
          // "isDefault": 0,
          userId: this.$store.state.userId,
        }
        if (this.$route.query.type == 'add') {
          param['isDefault'] = 0
          addAddress(param)
            .then((res) => {
              if (res.code == 200) {
                this.$message.success(this.$trans('添加成功', '添加成功'))
                this.form.contactName = ''
                this.form.contactPhone = ''
                this.form.addrDetail = ''
                this.form.areaId = []
                this.$router.history.push({
                  query: {
                    ...this.$route.query,
                  },
                  path: this.$route.query.editPath
                    ? this.$route.query.editPath
                    : '/address',
                })
              } else {
                this.$message.warning(
                  this.$trans('网络错误，请稍后再试', '网络错误，请稍后再试')
                )
              }
            })
            .catch(() => {
              this.$message.error(
                this.$trans('添加失败，请稍后再试', '添加失败，请稍后再试')
              )
            })
        } else {
          param['isDefault'] = this.form['isDefault']
          editAddress({
            ...param,
            id: JSON.parse(this.$route.query.address).id,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$message.success(this.$trans('修改成功', '修改成功'))
                this.$router.history.push({
                  path: this.$route.query.pathEdit
                    ? this.$route.query.pathEdit
                    : '/address',
                  query: {
                    ...this.$route.query,
                    data: JSON.stringify(param),
                  },
                })
              } else {
                this.$message.warning(
                  this.$trans('网络错误，请稍后再试', '网络错误，请稍后再试')
                )
              }
            })
            .catch(() => {
              this.$message.error(
                this.$trans('修改失败,请稍后再试', '修改失败,请稍后再试')
              )
            })
          return
        }

        return
      } else {
        this.$message.warning('请正确填写信息')
      }
    },
    // 将地址码转换为文本
    changeAreaText(adcode, arr) {
      return arr.filter((item) => {
        return item.adcode == adcode
      })[0]
    },
    // 将修改地址时地址栏初始化
    initAreaId() {
      let arr = []
      // console.log(this.options)
      this.options.forEach((item) => {
        if (item.districts) {
          item.districts.forEach((ite) => {
            if (ite.districts) {
              ite.districts.forEach((it) => {
                if (it.adcode == this.form.areaId) {
                  arr = [item.adcode, ite.adcode, it.adcode]
                }
              })
            }
          })
        }
      })
      this.form.areaId = arr
    },
  },
}
</script>
<style scoped>
.address-box-title {
  font-size: 16px;
  margin-bottom: 30px;
}
.btn-fill {
  background: #2a9dcc;
  cursor: pointer;
  margin-top: 30px;
}
</style>
<style>
.el-cascader {
  width: 100%;
}
.el-input {
  margin-bottom: 14px;
}
</style>
